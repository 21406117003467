export enum APPSTATE {
  NEW = 'new',
  NAVIGATION = 'navigation',
}

/**
 * CACHE KEYS
 */

export const currentUserKey = 'currentUser';

/**
 * APP CONFIGS
 */

export const PAGESIZES: number[] = [5, 10, 20, 50];
