import { COUNTRIES, COUNTRY, COUNTRYCONFIG, LANGUAGE } from "src/app/configs/countries.constant";

export class EnvironmentClass {
  public defaultCountry: COUNTRYCONFIG;
  public defaultLanguage: LANGUAGE;

  constructor(defaultCountry: COUNTRY) {
    this.defaultCountry = COUNTRIES.find((country) => country.code === defaultCountry) ?? COUNTRIES[0];
    this.defaultLanguage = this.defaultCountry.language;
  }

  public get supportedLanguages(): LANGUAGE[] {
    const supportedLanguages = COUNTRIES.map((country) => {
      return country.language;
    });

    return supportedLanguages;
  }
}
