import {Component, Input} from '@angular/core';
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {environment} from '../../../environments/environment';
import {Ocsp} from './ocsp.interface';

@Component({
  selector: 'app-ocsp',
  templateUrl: './ocsp.component.html',
  styleUrls: ['./ocsp.component.scss']
})
export class OcspComponent {

  ocsp!: Ocsp;
  @Input() path: string = '';
  constructor(private httpService: HttpClient) {}

  ngOnInit() {
    this.httpService.get(environment.ocspDirectory + this.path + '/config.json', {responseType: 'text'}).subscribe(
      data => {
        this.ocsp = JSON.parse(data) as Ocsp;
        console.log(this.ocsp);
      },
      (err: HttpErrorResponse) => {
        console.log (err.message);
      }
    );
  }

  navigate(link: string) {
    window.open(link);
  }
}
