import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import {environment} from '../../../environments/environment';
import {FILE_ACTIONS, PeriodicElement} from './documents.interface';

const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-documents-table',
  templateUrl: './documents-table.component.html',
  styleUrls: ['./documents-table.component.scss']
})
export class DocumentsTableComponent implements OnInit{

  constructor(private httpService: HttpClient) {
  }

  FILE_ACTIONS = FILE_ACTIONS;

  displayedColumns: string[] = [];
  @Input() dataSource = ELEMENT_DATA;
  @Input() pathToDocument!: string;
  @Input () fileType: FILE_ACTIONS.READ | FILE_ACTIONS.DOWNLOAD = FILE_ACTIONS.READ;

  ngOnInit() {
    this.displayedColumns = [];
    this.httpService.get(environment.documentsDirectory + this.pathToDocument + '/config.json', {responseType: 'text'}).subscribe(
      data => {
        this.dataSource = JSON.parse(data)['files'] as PeriodicElement [];	 // FILL THE ARRAY WITH DATA.
        this.fileType = JSON.parse(data)['documents-type'];
        this.displayedColumns = Object.keys(this.dataSource[0]);

      },
      (err: HttpErrorResponse) => {
        console.log (err.message);
      }
    );
  }

  downloadMyFile(name: string){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href',environment.documentsDirectory + this.pathToDocument + '/' + name);
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  actionFile(fileType: string, fileName: string) {
    if (fileType === 'read')
      window.open(environment.documentsDirectory + this.pathToDocument + '/' + fileName);
    else
      this.downloadMyFile(fileName);
  }
}
