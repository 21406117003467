import { SupportedLanguage } from "../core/i18n/i18n.service";

export enum COUNTRY {
  PT = 'pt',
  EN_GB = 'en-gb'
}

export interface LANGUAGE extends SupportedLanguage {
 locale: string;
}

export interface COUNTRYCONFIG {
  code: COUNTRY;
  name: string;
  language: LANGUAGE;
}

export const COUNTRIES: COUNTRYCONFIG[] = [
  {
    code: COUNTRY.PT,
    name: 'Portugal',
    language: {
      code: 'pt-PT',
      label: 'Português',
      locale: 'pt-PT',
      orientation: 'ltr',
    },
  },
  {
    code: COUNTRY.EN_GB,
    name: 'Great Britain',
    language: {
      code: 'en-GB',
      label: 'English',
       locale: 'en-GB',
      orientation: 'ltr',
    }
  }
];
