import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { Shell } from './layout/shell/shell.service';

const routes: Routes = [
  {
    path: 'publico',
    redirectTo: 'publico/area-principal',
    pathMatch: 'full',
  },
  {
    path: '',
    redirectTo: 'publico/area-principal',
    pathMatch: 'full'
  },
  Shell.childRoutes('publico', true,
  [
    {
      path: 'area-principal',
      loadChildren: () =>
        import('./layout/pages/main-area/main-area.module').then(
          (m) => m.MainAreaModule
        ),
    },
    {
      path: 'entidade-certificacao-cc',
      loadChildren: () =>
        import('./layout/pages/cert-entity-cc/cert-entity-cc.module').then(
          (m) => m.CertEntityCcModule
        ),
    },
    {
      path: 'entidade-certificacao-chavemoveldigital',
      loadChildren: () =>
        import('./layout/pages/cert-entity-mobile-digital-key/cert-entity-mobile-digital-key.module').then(
          (m) => m.CertEntityMobileDigitalKeyModule
        ),
    },
    {
      path: 'entidade-certificacao-autenticacao',
      loadChildren: () =>
        import('./layout/pages/cert-entity-authentication/cert-entity-authentication.module').then(
          (m) => m.CertEntityAuthenticationModule
        ),
    },
    {
      path: 'entidade-certificacao-assinatura',
      loadChildren: () =>
        import('./layout/pages/cert-entity-digital-signature/cert-entity-digital-signature.module').then(
          (m) => m.CertEntityDigitalSignatureModule
        ),
    },
    {
      path: 'politica-certificados',
      loadChildren: () =>
        import('./layout/pages/cert-policy/cert-policy.module').then(
          (m) => m.CertPolicyModule
        ),
    },
    {
      path: 'praticas-certificacao',
      loadChildren: () =>
        import('./layout/pages/cert-practices/cert-practices.module').then(
          (m) => m.CertPracticesModule
        ),
    },
    {
      path: 'servico-selos-temporais',
      loadChildren: () =>
        import('./layout/pages/timestamp-service/timestamp-service.module').then(
          (m) => m.TimestampServiceModule
        ),
    },
    {
      path: 'documentacao',
      loadChildren: () =>
        import('./layout/pages/documentation/documentation.module').then(
          (m) => m.DocumentationModule
        ),
    },
    {
      path: '**',
      redirectTo: '/publico/area-principal',
    },
  ])
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
