export enum DocumentsPaths {
  documentacao = 'documentacao',
  certificados_ec_cc = 'entidade-certificacao-cc/certificados',
  lista_revogacao_ec_cc = 'entidade-certificacao-cc/lista-revogacao',
  certificados_ec_assinatura_digital = 'entidade-certificacao-assinatura/certificados',
  lista_revogacao_ec_assinatura_digital = 'entidade-certificacao-assinatura/lista-revogacao',
  certificados_ec_autenticacao = 'entidade-certificacao-autenticacao/certificados',
  lista_revogacao_ec_autenticacao = 'entidade-certificacao-autenticacao/lista-revogacao',
  certificados_ec_chavemoveldigital = 'entidade-certificacao-chavemoveldigital/certificados',
  lista_revogacao_ec_chavemoveldigital = 'entidade-certificacao-chavemoveldigital/lista-revogacao',
  politica_certificados = 'politica-certificados',
  praticas_certificacao = 'praticas-certificacao',
  servico_selos_temporais = 'servico-selos-temporais'
}

export interface PeriodicElement {
  dn?: string;
  certificate?: string;
  valid_from?: string;
  valid_to?: string;
  file_name?: string;
  info_file?: string;
}

export enum FILE_ACTIONS {
  READ = 'read',
  DOWNLOAD = 'download'
}
