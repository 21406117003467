import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

import { RouterModule } from '@angular/router';

// Flexbox and CSS Grid (both)

/**
* COMPONENTS
*/
import { InlineLoaderComponent } from './components/inline-loader/inline-loader.component';
import { I18nService } from '../core/i18n/i18n.service';
import { AngularMaterialModule } from './angular-material.module';
import {BreadcrumbComponent} from "./components/breadcrumb/breadcrumb.component";

const COMPONENTS: Type<any>[] = [
  InlineLoaderComponent,
  BreadcrumbComponent
];

const SERVICES: Type<any>[] = [
  I18nService,
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    AngularMaterialModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    AngularMaterialModule,
    ...COMPONENTS
  ],
  providers: [
    {
      provide: MatPaginatorIntl,
    },
    ...SERVICES
  ],
  declarations: [...COMPONENTS],
})
export class SharedModule { }
