import { Inject, Injectable } from '@angular/core';

import { Subscription } from 'rxjs';

import { EnvironmentService } from '../environment/environment.service';

/**
 * Langs
 */
 import * as enGB from 'src/translations/en-GB.json';
 import * as ptPT from 'src/translations/pt-PT.json';
 

import { EnvironmentClass } from '../environment/environment.class';
import { environment } from 'src/environments/environment';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ENV } from 'src/app/configs/injection-tokens.constant';


const languageKey = 'language';

export interface SupportedLanguage {
  code: string;
  orientation: string;
  label: string;
}

/**
 * Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 */
export function extract(s: string) {
  return s;
}

@Injectable()
export class I18nService {
  defaultLanguage: string;
  supportedLanguages: SupportedLanguage[];

  private langChangeSubscription!: Subscription;

  constructor(
    private translateService: TranslateService,
    private app: EnvironmentService,
    @Inject(ENV) env: EnvironmentClass
  ) {
    translateService.setTranslation('en-GB', enGB);
    translateService.setTranslation('pt-PT', ptPT);

    this.defaultLanguage = env.defaultLanguage.code;
    this.supportedLanguages = env.supportedLanguages;
  }

  /**
   * Initializes i18n for the application.
   * Loads language from local storage if present, or sets default language.
   */
  init(defaultLanguage: string, supportedLanguages: SupportedLanguage[]) {
    this.defaultLanguage = defaultLanguage;
    this.supportedLanguages = supportedLanguages;

    this.language = '';

    this.langChangeSubscription = this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      localStorage.setItem(languageKey, event.lang);
    });
  }

  /**
   * Sets the current language.
   * Note: The current language is saved to the local storage.
   * If no parameter is specified, the language is loaded from local storage (if present).
   */
  set language(language: string) {

    let supportedLanguage = this.supportedLanguages.find((lang) => lang.code === language);

    if (language && !supportedLanguage) {
      language = language.split('-')[0];

      supportedLanguage = this.supportedLanguages.find((lang) => lang.code.startsWith(language));
    }

    if (!supportedLanguage) {
      supportedLanguage = this.supportedLanguages.find((lang) => lang.code === this.defaultLanguage);
    }

    language = supportedLanguage ? supportedLanguage.code : environment.supportedCountries[0].language.locale;

    this.app.orientation = supportedLanguage ? supportedLanguage.orientation : environment.supportedCountries[0].language.orientation;

    this.translateService.use(language);

  }

  /**
   * Gets the current language.
   */
  get language(): string {
    return (
      this.translateService.currentLang ||
      localStorage.getItem(languageKey) ||
      this.translateService.getBrowserCultureLang() ||
      this.app.envClass.defaultCountry.language.code
    );
  }

  /**
   * Cleans up language change subscription.
   */
  destroy() {
    if (this.langChangeSubscription) {
      this.langChangeSubscription.unsubscribe();
    }
  }
}
