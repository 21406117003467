import { Routes, Route } from '@angular/router';
import { ShellComponent } from './shell.component';


export class Shell {

  static childRoutes(basePath: string, isLanguageRequired: boolean, routes: Routes): Route {
    return {
      path: basePath,
      component: ShellComponent,
      children: routes,
      data: {
        isLanguageRequired: isLanguageRequired,
        shellType: 'full',
        reuse: true,
      },
    };
  }
  static childRoutesNoAuthentication(basePath: string, isLanguageRequired: boolean, routes: Routes): Route {
    return {
      path: basePath,
      component: ShellComponent,
      children: routes,
      data: {
        isLanguageRequired: isLanguageRequired,
        shellType: 'simple',
        reuse: false,
      },
    };
  }
}
