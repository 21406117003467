import {Inject, Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { APPSTATE } from './environment.constant';
import { EnvironmentClass } from './environment.class';
import { COUNTRYCONFIG } from 'src/app/configs/countries.constant';
import { ENV } from 'src/app/configs/injection-tokens.constant';



@Injectable()
export class EnvironmentService {
  /**
   * App State
   */

  
  public ApplicationStateNotification: BehaviorSubject<{ state: APPSTATE }> = new BehaviorSubject<{ state: APPSTATE }>({
    state: APPSTATE.NEW,
  });


  /**
   * View Flags
   */
  private _orientation: string;
  private _selectedCountry: COUNTRYCONFIG;

  /**
   * Environment Class
   */
  private _env: EnvironmentClass;

  constructor(@Inject(ENV) env: EnvironmentClass) {

    this._env = env;
    this._orientation = 'ltr';
    this._selectedCountry = env.defaultCountry;

  }
 
  public get isLeftToRight(): boolean {
    return this._orientation === 'ltr';
  }

  public get isRightToLeft(): boolean {
    return this._orientation === 'rtl';
  }

  public set orientation(value: string) {
    this._orientation = value;
  }


  public get envClass(): EnvironmentClass {
    return this._env;
  }

  public get selectedCountry(): COUNTRYCONFIG{
    return this._selectedCountry;
  }

  public set selectedCountry(country: COUNTRYCONFIG){
    this._selectedCountry = country;

  }
}
