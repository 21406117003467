
export interface AREACONFIG {
  [x: string]: any;
  code: string;
  name: string;
  route: string;
  roles?: string[];
  icon: string;
  showOnMenu?: boolean;
  subAreas?: AREACONFIG[];
}

export const AREAS: AREACONFIG[] = [
  {
    code: 'main-area',
    name: 'menu.items.main-area.title',
    route: '/publico/area-principal',
    icon: 'home'
  },
  {
    code: 'cert-entity-cc',
    name: 'menu.items.cert-entity-cc.title',
    route: '/publico/entidade-certificacao-cc',
    icon: 'remove',
    subAreas: [
      {
        code: 'cert-entity-cc-certificates',
        name: 'menu.items.cert-entity-cc.sub-items.certificates',
        route: '/publico/entidade-certificacao-cc/certificados',
        icon: 'arrow_right',
      },
      {
        code: 'cert-entity-cc-revocation-list',
        name: 'menu.items.cert-entity-cc.sub-items.revocation-list',
        route: '/publico/entidade-certificacao-cc/lista-revogacao',
        icon: 'arrow_right',
      },
      {
        code: 'cert-entity-cc-ocsp',
        name: 'menu.items.cert-entity-cc.sub-items.ocsp',
        route: '/publico/entidade-certificacao-cc/ocsp',
        icon: 'arrow_right'
      }
    ],
  },
  {
    code: 'cert-entity-digital-signature',
    name: 'menu.items.cert-entity-digital-signature.title',
    route: '',
    icon: 'remove',
    subAreas: [
      {
        code: 'cert-entity-digital-signature-certificates',
        name: 'menu.items.cert-entity-digital-signature.sub-items.certificates',
        route: '/publico/entidade-certificacao-assinatura/certificados',
        icon: 'arrow_right',
      },
      {
        code: 'cert-entity-digital-signature-revocation-list',
        name: 'menu.items.cert-entity-digital-signature.sub-items.revocation-list',
        route: '/publico/entidade-certificacao-assinatura/lista-revogacao',
        icon: 'arrow_right',
      },
      {
        code: 'cert-entity-digital-signature-ocsp',
        name: 'menu.items.cert-entity-digital-signature.sub-items.ocsp',
        route: '/publico/entidade-certificacao-assinatura/ocsp',
        icon: 'arrow_right'
      }
    ],
  },
  {
    code: 'cert-entity-authentication',
    name: 'menu.items.cert-entity-authentication.title',
    route: '',
    icon: 'remove',
    subAreas: [
      {
        code: 'cert-entity-authentication-certificates',
        name: 'menu.items.cert-entity-authentication.sub-items.certificates',
        route: '/publico/entidade-certificacao-autenticacao/certificados',
        icon: 'arrow_right',
      },
      {
        code: 'cert-entity-authentication-revocation-list',
        name: 'menu.items.cert-entity-authentication.sub-items.revocation-list',
        route: '/publico/entidade-certificacao-autenticacao/lista-revogacao',
        icon: 'arrow_right',
      },
      {
        code: 'cert-entity-authentication-ocsp',
        name: 'menu.items.cert-entity-authentication.sub-items.ocsp',
        route: '/publico/entidade-certificacao-autenticacao/ocsp',
        icon: 'arrow_right'
      }
    ],
  },
  /*
  {
    code: 'cert-entity-digital-mobile-key',
    name: 'menu.items.cert-entity-digital-mobile-key.title',
    route: '',
    icon: 'remove',
    subAreas: [
      {
        code: 'cert-entity-digital-mobile-key-certificates',
        name: 'menu.items.cert-entity-digital-mobile-key.sub-items.certificates',
        route: '/entidade-certificacao-chavemoveldigital/certificados',
        icon: 'arrow_right',
      },
      {
        code: 'cert-entity-digital-mobile-key-revocation-list',
        name: 'menu.items.cert-entity-cc.sub-items.revocation-list',
        route: '/entidade-certificacao-chavemoveldigital/lista-revogacao',
        icon: 'arrow_right',
      },
      {
        code: 'cert-entity-digital-mobile-key-ocsp',
        name: 'menu.items.cert-entity-digital-mobile-key.sub-items.ocsp',
        route: '/entidade-certificacao-chavemoveldigital/ocsp',
        icon: 'arrow_right'
      }
    ],
  },
  {
    code: 'timestamp-service',
    name: 'menu.items.timestamp-service.title',
    route: '/servico-selos-temporais',
    icon: 'arrow_right',
  },
  {
    code: 'documentation',
    name: 'menu.items.documentation.title',
    route: '/documentacao',
    icon: 'arrow_right',
  },*/
  {
    code: 'cert-policy',
    name: 'menu.items.cert-policy.title',
    route: '/publico/politica-certificados',
    icon: 'arrow_right',
  },
  {
    code: 'cert-practices',
    name: 'menu.items.cert-practices.title',
    route: '/publico/praticas-certificacao',
    icon: 'arrow_right'
  }
];
