import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-inline-loader',
  template: `
    <div class="center" *ngIf="!!isLoading">
        <mat-spinner [diameter]="diameter" class="custom-spin"></mat-spinner>
    </div>
  `,
  styles: [
    `
      .custom-spin {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .center {
        position: absolute;
        top: 50%;
        left: 50%;
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        z-index: 10;
      }
    `,
  ],
})
export class InlineLoaderComponent implements OnInit {
  @Input() isLoading!: boolean;
  @Input() diameter!: number;

  constructor() {
    this.diameter = 50;
  }

  ngOnInit() {}
}
