import { COUNTRIES, COUNTRY } from "src/app/configs/countries.constant";


export const environment = {
  production: false,
  serverUrl: '',
  documentsDirectory: '/',
  notificationsDirectory: '/assets/notificacoes/',
  ocspDirectory: '/assets/ocsp/',
  mock: false,
  defaultCountry: COUNTRY.PT,
  supportedCountries: COUNTRIES,
};
