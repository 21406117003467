import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { DocumentsTableComponent } from './documents-table/documents-table.component';
import {OcspComponent} from "./ocsp/ocsp.component";

@NgModule({
  imports: [
    SharedModule,
    CommonModule
  ],
  declarations: [
    DocumentsTableComponent,
    OcspComponent
  ],
  exports: [
    DocumentsTableComponent,
    OcspComponent
  ]
})
export class ComponentsModule {}
