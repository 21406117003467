<div class="app-main-wrapper-full flex-fill flex-column" >
  <mat-toolbar color="primary" >

    <button
      *ngIf="areas !== null && this.router.url !== '/backoffice/areas'"
      type="button"
      aria-label="Toggle sidenav"
      mat-icon-button
      (click)="drawer.toggle()"
    >

      <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
    <a href="https://www.autenticacao.gov.pt/" class="ml-2">
      <img
        class="logo-img"
        src="assets/images/icon-gov.png"
        alt="INCM"
      />
    </a>
    <a class="ml-2"><h1 style="color:white">Cartão de cidadão</h1></a>
    <span class="spacer"></span>

  </mat-toolbar>

  <mat-drawer-container class="full-heigth" autosize>
    <mat-drawer #drawer mode="side" opened>
      <mat-nav-list *ngIf="areas">
        <ng-container *ngFor="let area of areas" >
          <div class="d-flex">
          <a
            mat-list-item
            class=""
            *ngIf="!area.subAreas"
            [routerLink]="[area.route]"
            routerLinkActive="selected"
          >
            <mat-icon class="dark-blue mr-1" mat-list-icon *ngIf="area.code==='main-area'">{{
              area.icon
            }}</mat-icon>
            <span class="dark-blue mr-2" translate>{{ area.name }}</span>
          </a>
          </div>
          <mat-expansion-panel
            *ngIf="area.subAreas"
            [class.mat-elevation-z0]="true"
          >
            <mat-expansion-panel-header class="personalized-panel">
              <span translate> {{ area.name }}</span>
            </mat-expansion-panel-header>
            <mat-nav-list>
              <div *ngFor="let subArea of area.subAreas" class="d-flex">
                <a
                  mat-list-item
                  class=""
                  [routerLink]="[subArea.route]"
                  routerLinkActive="selected"
                  (click)="drawer.toggle()"
                >
                  <mat-icon class="dark-blue mr-1" mat-list-icon>{{
                    subArea.icon
                  }}</mat-icon>
                  <span class="dark-blue mr-2" translate>{{
                    subArea.name
                  }}</span>
                </a>
              </div>
            </mat-nav-list>
          </mat-expansion-panel>
        </ng-container>
      </mat-nav-list>
    </mat-drawer>
    <mat-drawer-content class="full-heigth">

      <div [ngClass]="['content']">
        <router-outlet></router-outlet>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
