import { LOCALE_ID, NgModule, Type } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { ShellModule } from './layout/shell/shell.module';


/**
 * LOCALES
 */
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { EnvironmentClass } from './core/environment/environment.class';
import { environment } from 'src/environments/environment';
import { ENV } from './configs/injection-tokens.constant';
import { ComponentsModule } from './components/components.module';


const MODULES: Type<any>[] = [CoreModule, SharedModule, ShellModule, ComponentsModule];

const env = new EnvironmentClass(environment.defaultCountry);
const locale = env.defaultLanguage.locale;

 export function envFactory() {
   return env;
 }

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),

    ...MODULES,
    AppRoutingModule,

  ],
  providers: [
    {
      provide: ENV,
      useFactory: envFactory,
    },
    {
      provide: LOCALE_ID,
      useValue: locale,

    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
