<table mat-table [dataSource]="dataSource">
  <ng-container *ngFor="let i = index; let column of displayedColumns" matColumnDef="{{column}}">
    <ng-container [ngSwitch]="column === 'file_name'">
      <ng-container *ngSwitchCase="false">
        <th mat-header-cell *matHeaderCellDef translate> documents-table.columns.{{column}} </th>
      </ng-container>
      <ng-container *ngSwitchCase="true">
        <ng-container *ngIf="fileType === FILE_ACTIONS.READ">
          <th mat-header-cell *matHeaderCellDef translate> documents-table.columns.open </th>
        </ng-container>
        <ng-container *ngIf="fileType === FILE_ACTIONS.DOWNLOAD">
          <th mat-header-cell *matHeaderCellDef translate> documents-table.columns.download </th>
        </ng-container>
      </ng-container>
    </ng-container>

    <td mat-cell *matCellDef="let element">
      <ng-container [ngSwitch]="column === 'file_name'">
        <ng-container *ngSwitchCase="false">
          {{element[column]}}
        </ng-container>
        <ng-container *ngSwitchCase="true">
          <ng-container  *ngIf="fileType === FILE_ACTIONS.DOWNLOAD">
            <button mat-icon-button (click)="actionFile(fileType, element['file_name'])" [attr.aria-label]="'documents-table.columns.download' | translate">
              <mat-icon>save_alt</mat-icon>
            </button>
          </ng-container>
          <ng-container  *ngIf="fileType === FILE_ACTIONS.READ">
            <button mat-icon-button (click)="actionFile(fileType, element['file_name'])" [attr.aria-label]="'documents-table.columns.open' | translate">
              <mat-icon>visibility</mat-icon>
            </button>
          </ng-container>
        </ng-container>
      </ng-container>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
