import { registerLocaleData } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { merge } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { EnvironmentService } from './core/environment/environment.service';
import { I18nService } from './core/i18n/i18n.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'CC PKI';

  private locale: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private app: EnvironmentService,
    @Inject(LOCALE_ID) locale: string
  ) {
    this.locale = locale;
  }

  ngOnInit() {


    const defaultCountry = this.app.envClass.defaultCountry;
    const supportedLanguages = this.app.envClass.supportedLanguages;

    this.i18nService.init(defaultCountry.language.code, supportedLanguages);

    if (this.locale === 'en-GB') {
      import('@angular/common/locales/en-GB').then(
        (module) => {
          registerLocaleData(module.default, 'en-GB');
        },
        (error) => {
          console.log('Unable to load a locale successfully.');
        }
      );
    } else if (this.locale === 'pt-PT') {
      import('@angular/common/locales/pt-PT').then(
        (module) => {
          registerLocaleData(module.default, 'pt-PT');
        },
        (error) => {
          console.log('Unable to load a locale successfully.');
        }
      );
    } else {
      console.log('The defined locale is not supported.');
    }

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));

    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {

          this.titleService.setTitle('CC PKI');
  
      });
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }
}
