import {
  Component,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AREACONFIG, AREAS } from 'src/app/configs/areas.constant';
import { EnvironmentService } from 'src/app/core/environment/environment.service';
import { MatDialog } from '@angular/material/dialog';
import { I18nService, SupportedLanguage } from 'src/app/core/i18n/i18n.service';
import { COUNTRIES } from 'src/app/configs/countries.constant';


@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnDestroy, OnInit {

  sidenavCollapsed = false;
  public shellType: string;
  public isLanguageRequired: boolean;
  public areas!: AREACONFIG[];
  public hasAdminArea: any = undefined;


  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private envService: EnvironmentService,
    private i18nService: I18nService,
    public dialog: MatDialog,
  ) {

    this.shellType = 'simple';
    this.isLanguageRequired = false;

  }

  ngOnInit() {
    this.areas = AREAS;
    console.log('TESTE', this.areas)
  }

  ngOnDestroy() {
  }


  subAreasList(areas: string) {
    if (this.areas.length !== 0) {
      const findSub = this.areas[0].subAreas?.find((area) =>
        areas.includes(area.route)
      );
      return findSub?.subAreas;
    }

    return [];
  }

  /**
   * Language
   */
  get languages(): SupportedLanguage[] {
    return this.i18nService.supportedLanguages;
  }

  get language(): SupportedLanguage {
    const selected = this.i18nService.language;

    return (
      this.i18nService.supportedLanguages.find(
        (lang) => lang.code === selected
      ) ?? this.i18nService.supportedLanguages[0]
    );
  }

  changeLang(langCode: string): void {
    const selected =
      COUNTRIES.find((country) => country.language.code === langCode) ??
      this.envService.selectedCountry;
    this.envService.selectedCountry = selected;
    this.i18nService.language = langCode;
  }

  flag(code: string): string {
    return `/assets/icons/langs/${code}.png`;
  }
}
