import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  template: `
    <ng-container *ngFor="let item of items; let last = last">
      <a *ngIf="item.url" (click)="navigate(item.url)">{{item.label}}</a>
      <span *ngIf="item.bold" style="font-weight: 600">{{item.label}}</span>
      <span *ngIf="!item.url && !item.bold">{{item.label}}</span>
      <mat-icon *ngIf="!last">keyboard_arrow_right</mat-icon>
    </ng-container>
  `,
  styles: [`
    span {
        font-size: 16px;
    }
    a {
      font-size: 16px;
      text-decoration: none;
      color: #007bff;
      cursor: pointer;
    }
    .mat-icon {
      margin-bottom: -7px;
    }
  `],
})
export class BreadcrumbComponent implements OnInit {
  @Input() items!: { label: string, url?: string, bold?: boolean }[];

  constructor(private router: Router) {}

  ngOnInit() {}

  navigate(url: string) {
    this.router.navigate([url]);
  }
}
