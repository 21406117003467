import { NgModule, Type } from '@angular/core';

import { ShellComponent } from './shell.component';

import { SharedModule } from 'src/app/shared/shared.module';

/**
 * ENTRY COMPONENTS
 */
// import { Something } from './somewhere';

const ENTRYCOMPONENTS: Type<any>[] = [];

@NgModule({
  imports: [SharedModule],
  declarations: [...ENTRYCOMPONENTS, ShellComponent],
})
export class ShellModule {}
