import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

/**
 * SERVICES
 */
import { I18nService } from './i18n/i18n.service';
import { EnvironmentService } from './environment/environment.service';


@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    HttpClientModule,
    RouterModule
  ],
  providers: [
    I18nService,
    EnvironmentService
  ],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
